.Map2 {


  .map-container {
    height: 100vh; width: 100%; position: relative; overflow: hidden;
  }

  .highlighted {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    max-width: fit-content;
    /* card style */
    background-color: #000;
    padding: 10px;
    border-radius: 5px;
    /* text style */
    color: #fff;
    font-size: 0.8rem;
    text-align: center;
    /* animation */
    transform: scale(0);
    animation: 400ms reveal forwards;
  }

  .highlighted button {
    color: rgb(255, 255, 107);
  }

  @keyframes reveal {
    to {
      transform: scale(1);
    }
  }

  @media screen and (max-width: 767px) {
    .container {
      flex-direction: column;
      align-items: flex-start;
    }
    .left {
      flex-direction: column;
    }
    .left > div:not(.info) {
      padding-left: 0;
    }
  }
}

.marker {
  width: 50px;
  height: 50px;
  position: relative;
  top: -50px;
  left: -25px;
}

.google-map {
  height: 100vh; width: 100%; position: relative; overflow: hidden;
}



